// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    tenantImportReset,
    tenantValidationErrorDownload,
    dataMapImportReset,
    dataMapImportDownloadError,
    crmReset,
    crmDownloadErrorMapping,
    userValidationErrorDownload,
    userImportReset,
    recordsValidationErrorDownload,
    fundsValidationErrorDownload,
    fundAssetsValidationErrorDownload,
    fundDonorsValidationErrorDownload,
    downloadRecordFyErrorSheet,
    fundImpactsValidationErrorDownload,
    fundImpactsFyAssetsValidationErrorDownload,
    fundImpactsFyDownloadsValidationErrorDownload,
    fundPerformancesValidationErrorDownload,
    fundPerformancesFyValidationErrorDownload,
    downloadRecordGivingErrorSheet,
    recordImportReset,
    fundImpactImportReset,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from '../../../helpers/downloadFile/downloadFile';

const PreflightDataModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();

    const {
        tenantDownloadMapping,
        schemaDownloadMapping,
        downloadMapping,
        userValidationErrorDownload_,
        assetImpactErrorDownload,
        assetImpactErrorDownloadFyDownloads,
        assetImpactErrorDownloadFyAssets,
        assetImpactErrorDownloadErrorSheet,
    } = useSelector((state) => ({
        tenantDownloadMapping: state?.tenantImport?.tenantValidationErrorDownload,
        schemaDownloadMapping: state?.dataMapImport?.downloadError,
        downloadMapping: state?.crm?.downloadMapping,
        userValidationErrorDownload_: state?.userImport?.userValidationErrorDownload,
        assetImpactErrorDownload: state?.recordImport?.recordsValidationErrorDownload,
        assetImpactErrorDownloadFyDownloads: state?.fundImpactImport?.fundImpactsFyDownloadsValidationErrorDownload,
        assetImpactErrorDownloadFyAssets: state?.fundImpactImport?.fundImpactsFyAssetsValidationErrorDownload,
        assetImpactErrorDownloadErrorSheet: state?.recordImport?.downloadRecordGivingErrorSheet,
    }));

    useEffect(() => {
        if (schemaDownloadMapping) {
            downloadFile(
                schemaDownloadMapping?.data,
                'application/zip',
                schemaDownloadMapping?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(dataMapImportReset());
        }
        if (tenantDownloadMapping) {
            downloadFile(
                tenantDownloadMapping?.data,
                'application/zip',
                tenantDownloadMapping?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(tenantImportReset());
        }

        if (downloadMapping) {
            downloadFile(
                downloadMapping?.data,
                'application/zip',
                downloadMapping?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(crmReset());
        }

        if (userValidationErrorDownload_) {
            downloadFile(
                userValidationErrorDownload_?.data,
                'application/zip',
                userValidationErrorDownload_?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(userImportReset());
        }

        if (assetImpactErrorDownload) {
            downloadFile(
                assetImpactErrorDownload?.data,
                'application/zip',
                assetImpactErrorDownload?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(recordImportReset());
        }

        if (assetImpactErrorDownloadFyAssets) {
            downloadFile(
                assetImpactErrorDownloadFyAssets?.data,
                'application/zip',
                assetImpactErrorDownloadFyAssets?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(fundImpactImportReset());
        }
        if (assetImpactErrorDownloadFyDownloads) {
            downloadFile(
                assetImpactErrorDownloadFyDownloads?.data,
                'application/zip',
                assetImpactErrorDownloadFyDownloads?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(fundImpactImportReset());
        }
        if (assetImpactErrorDownloadErrorSheet) {
            downloadFile(
                assetImpactErrorDownloadErrorSheet?.data,
                'application/zip',
                assetImpactErrorDownloadErrorSheet?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(recordImportReset());
        }
    }, [
        downloadMapping,
        userValidationErrorDownload_,
        assetImpactErrorDownload,
        assetImpactErrorDownloadFyDownloads,
        assetImpactErrorDownloadFyAssets,
        assetImpactErrorDownloadErrorSheet,
        tenantDownloadMapping,
        schemaDownloadMapping,
    ]);

    const downloadAnnotationSheet = (errorId, data) => {
        try {
            let validationDispatch = props?.data?.validationDispatch;
            if (props?.data?.KeyName) {
                props?.data?.handleDownloadError({
                    pkXLSErrorId: errorId,
                    isPreflight: true,
                    isUpdater: props?.data?.isUpdater,
                });
            } else if (validationDispatch) {
                if (validationDispatch?.allDispacthes[0] === 'recordsValidationErrorDownload') {
                    dispatch(
                        recordsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundsValidationErrorDownload') {
                    dispatch(
                        fundsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'tenantValidationErrorDownload') {
                    dispatch(
                        tenantValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            orgId: props?.data?.orgId,
                            importModuleId: props?.data?.importModuleId,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'schemaValidationErrorDownload') {
                    dispatch(
                        dataMapImportDownloadError({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            dataScopeId: props?.data?.dataScopeId,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'crmValidationErrorDownload') {
                    dispatch(
                        crmDownloadErrorMapping({
                            // isUpdater: validationDispatch?.isUpdater,
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            type: data?.data?.tabType,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundAssetsValidationErrorDownload') {
                    dispatch(
                        fundAssetsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundDonorsValidationErrorDownload') {
                    dispatch(
                        fundDonorsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'downloadRecordFyErrorSheet') {
                    dispatch(
                        downloadRecordFyErrorSheet({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundImpactsValidationErrorDownload') {
                    dispatch(
                        fundImpactsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundImpactsFyAssetsValidationErrorDownload') {
                    dispatch(
                        fundImpactsFyAssetsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundImpactsFyDownloadsValidationErrorDownload') {
                    dispatch(
                        fundImpactsFyDownloadsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundPerformancesValidationErrorDownload') {
                    dispatch(
                        fundPerformancesValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundPerformancesFyValidationErrorDownload') {
                    dispatch(
                        fundPerformancesFyValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'downloadRecordGivingErrorSheet') {
                    dispatch(
                        downloadRecordGivingErrorSheet({
                            pkXLSErrorId: errorId,
                            isPreflight: true,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                }
            } else if (props?.data?.KeyConponent === 'tenantPreflightData') {
                dispatch(
                    tenantValidationErrorDownload({
                        pkXLSErrorId: errorId,
                        isPreflight: true,
                        orgId: props?.data?.orgId,
                        importModuleId: props?.data?.importModuleId,
                    })
                );
            } else if (props?.data?.KeyConponent === 'schemaPreflightData') {
                dispatch(
                    dataMapImportDownloadError({
                        pkXLSErrorId: errorId,
                        isPreflight: true,
                        dataScopeId: props?.data?.dataScopeId,
                    })
                );
            } else if (props?.data?.KeyConponent === 'crmPreflightData') {
                dispatch(
                    crmDownloadErrorMapping({
                        pkXLSErrorId: errorId,
                        isPreflight: true,
                        type: props?.data?.type,
                        //isUpdater: props?.data?.isUpdater,
                    })
                );
            } else {
                dispatch(
                    userValidationErrorDownload({
                        pkXLSErrorId: errorId,
                        isPreflight: true,
                        isUpdater: props?.data?.isUpdater,
                    })
                );
            }
        } catch (error) {}
    };

    const handleImport = () => {
        props?.data?.handleImport();
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    {props?.data?.data?.code === 406 ? (
                        <div>
                            <p>The following results would be achieved by importing this data file as-is:</p>
                            <p>
                                <b>{props?.data?.data?.failedRowCount}</b> rows have invalid characters, please remove
                                them and try again. Download the annotated spreadsheet to learn which row, correct the
                                data and test again.
                            </p>
                            <div className="button-list d-flex justify-content-center mt-2">
                                <ButtonElement
                                    name="Download Annotated Spreadsheet"
                                    variant="outline-primary"
                                    type="button"
                                    onClick={() =>
                                        downloadAnnotationSheet(props?.data?.data?.pkXlsErrorId, props?.data)
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>The following results would be achieved by importing this data file as-is:</p>
                            {props?.data?.data?.successRowCount > 0 && (
                                <div className="success">
                                    <p>
                                        Data for <b>{props?.data?.data?.successRowCount}</b> rows <b>would pass</b> the
                                        validation process.
                                    </p>
                                </div>
                            )}
                            {props?.data?.data?.success && (
                                <div className="success">
                                    <p>
                                        Data for <b>All</b> rows <b>would pass</b> the validation process.
                                    </p>
                                </div>
                            )}
                            {props?.data?.data?.failedRowCount > 0 && (
                                <div className="fail hide">
                                    <p>
                                        Data for{' '}
                                        <b>
                                            {props?.data?.data?.failedRowCount > 0 &&
                                            props?.data?.data?.successRowCount === 0
                                                ? 'all'
                                                : props?.data?.data?.failedRowCount}
                                        </b>{' '}
                                        rows <b>would fail</b> the validation process; download the annotated
                                        spreadsheet to learn why, correct the data, and test again.
                                    </p>
                                    <div className="button-list d-flex justify-content-center mt-2">
                                        <ButtonElement
                                            name="Download Annotated Spreadsheet"
                                            variant="outline-primary"
                                            type="button"
                                            onClick={() =>
                                                downloadAnnotationSheet(props?.data?.data?.pkXlsErrorId, props?.data)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            {!props?.data?.isNotification ? (
                <div className="modal-footer d-flex justify-content-center mt-3">
                    <ButtonElement
                        name="Upload"
                        variant="primary"
                        type="button"
                        onClick={handleImport}
                        disabled={props?.data?.data?.successRowCount > 0 || props?.data?.data?.success ? false : true}
                    />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) => {
                            if (!props?.data?.isNotification) props.data.resetForm();
                            if (props?.data?.KeyConponent) {
                                modalDispatch({
                                    type: modalActions.RESET_MODAL,
                                });
                            } else {
                                modalDispatch({
                                    type: modalActions.CLOSE_MODAL,
                                });
                            }
                        }}
                    />
                </div>
            ) : (
                <div className="modal-footer d-flex justify-content-center mt-3">
                    <ButtonElement
                        name="Close"
                        variant="light"
                        type="button"
                        onClick={(e) => {
                            if (!props?.data?.isNotification) props.data.resetForm();
                            if (props?.data?.KeyConponent) {
                                modalDispatch({
                                    type: modalActions.RESET_MODAL,
                                });
                            } else {
                                modalDispatch({
                                    type: modalActions.CLOSE_MODAL,
                                });
                            }
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default PreflightDataModal;
