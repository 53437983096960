import React, { useEffect, useState } from 'react';

const CustomTimePicker = (props) => {
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');

    useEffect(() => {
        if (props?.getValues) {
            const [hours, minutes, seconds] =
                props?.getValues === '00:00:00'
                    ? props?.defaultValues?.split(':').map(Number)
                    : props?.getValues?.split(':').map(Number);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        } else {
            const [hours, minutes, seconds] = props?.defaultValues?.split(':').map(Number);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }
    }, []);

    useEffect(() => {
        props?.getFormattedTime &&
            props.getFormattedTime(
                `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
                    2,
                    '0'
                )}`
            );
    }, [hours, minutes, seconds]);

    const handleInputChange = (e, type) => {
        const value = e.target.value;
        if (/^[0-9]*$/.test(value)) {
            switch (type) {
                case 'hours':
                    if (value <= 23) setHours(value); // Limit hours to 23
                    break;
                case 'minutes':
                    if (value <= 59) setMinutes(value); // Limit minutes and seconds to 59
                    break;
                case 'seconds':
                    if (value <= 59) setSeconds(value); // Limit minutes and seconds to 59
                    break;
            }
        }
    };

    return (
        <div className="custom-time-picker">
            <input
                name={props?.name + '-hours'}
                type="text"
                value={hours}
                maxLength="2"
                placeholder={props?.label || 'hh'}
                onChange={(e) => handleInputChange(e, 'hours')}
                className="custom-time-picker-input"
            />
            <span>:</span>
            <input
                name={props?.name + '-minutes'}
                type="text"
                value={minutes}
                maxLength="2"
                placeholder={props?.label || 'mm'}
                onChange={(e) => handleInputChange(e, 'minutes')}
                className="custom-time-picker-input"
            />
            <span>.</span>
            <input
                name={props?.name + '-seconds'}
                type="text"
                value={seconds}
                maxLength="2"
                placeholder={props?.label || 'ss'}
                onChange={(e) => handleInputChange(e, 'seconds')}
                className="custom-time-picker-input"
            />
        </div>
    );
};

export default CustomTimePicker;
