import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { boldString, Message } from '../message';

const urlValidator =
    /^(http(s)?:\/\/(www\.)?)?[A-Za-z0-9]+([\-\+\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const phoneValidator = /^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;
export const spaceAndSpecialCharacterValidatior =
    /^(?![_-])(?!.*?[^A-Za-z0-9_-])(?!.*?[_-]{2}).*[A-Za-z0-9]*(?!<[_-])$/;
const nameValidator = /^[a-zA-Z0-9¡-ÿœŸƒ_\s',.*():&!-]+$/;
const numberWithHyphenAndDotValidator = /^(-?\d+(?:\.\d+)?|.\d+)$/;
const emailValidator = /^[a-zA-Z0-9](?!.*?[._'+-]{2}).*[a-zA-Z0-9._'+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Validation change 25/05/2023 --> updated 29/05/2023
const ALPHANUMERIC_WITH_SPECIAL_CHARACTERS = /^[A-Za-z0-9\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
const ADDRESS = /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč`\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
const NAME_AND_IMPACT_FIELDS =
    /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
const CONTENT_PANEL_NAME_CHECK =
    /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
const FREE_TEXT = /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
// New Validation added 29/05/2023
const MATCH_NAME = /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s&*()_:’]*$/;
const PREFIX_AND_SUFFIX = /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
const CITY_STATE_COUNTRY = /^[a-zA-Z0-9\s,.:'()/=’-]*$/;
const ZIP_OR_POSTAL = /^[a-zA-Z0-9\s,.:()/’-]*$/;
const CLASS_YEAR = /^[A-Za-z0-9\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠-]*$/;
const GROUP_NAME = /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s:',()&_;’-]*$/;
const IMPACT_YEAR = /^[a-zA-Z0-9\s,;:_'’-]*$/;
const TITLE = /^[A-Za-z0-9¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč\s~!@#$%&*()_+|:?,.\\/;'‘\\[\]\\\\=’–‚„®©℗™℠"-]*$/;
const ACCENT_CHAR = '¡-ÿœŸƒÇçŠšŽžŒıāēīōūč̇ůůťţśřćč';

export const toUnicodeVariant = (str) => {
    alert(str);
};

export const loginValidation = yupResolver(
    yup.object().shape({
        username: yup
            .string()
            .matches(emailValidator, 'Please enter a valid Username/Email.')
            .required('Enter Username/Email.'),
        password: yup.string().required('Enter password.'),
    })
);

export const publicLoginValidation = yupResolver(
    yup.object().shape({
        username: yup
            .string()
            .matches(emailValidator, 'Please enter a valid Username/Email.')
            .required('Enter Username/Email.'),
    })
);

export const signUpValidation = yupResolver(
    yup.object().shape({
        fullName: yup.string().required('Please enter Fullname.'),
        email: yup
            .string()
            .required('Please enter Email.')
            .matches(emailValidator, 'Please enter a valid Username/Email.'),
        password: yup.string().required('Enter password.'),
    })
);

export const forgetPasswordValidation = yupResolver(
    yup.object().shape({
        email: yup
            .string()
            .matches(emailValidator, 'Please enter a valid Username/Email.')
            .required('Please enter your registered Username/Email.'),
    })
);

export const setPasswordValidation = yupResolver(
    yup.object().shape({
        newPassword: yup
            .string()
            .required(Message.GenericMsg)
            .matches(/[A-Z]/, 'Please add one upper case.')
            .matches(/[a-z]/, 'Please add one lower case.')
            .matches(/[0-9]/, 'Please add one digit.')
            .min(8, 'Password should be 8-15 characters in length.')
            .max(15, 'Password should not be greater 15 characters.')
            .matches(/[!@#$%^&+=.]/, 'Please add a special character (!@#$%^.&+=).')
            .matches(
                /^[a-zA-Z0-9\s!@#$%^&+=.]+$/,
                'Incompatible special characters; ' + boldString('! @ # $ % ^ & + = . ') + ' permitted.'
            )
            .matches(/^(?!.*(\w)\1{2,}).+$/, 'Contain no more than 2 consecutively repeating characters.'),
        confirmPassword: yup.string().max(100).required(Message.GenericMsg),
    })
);
export const mfaValidation = yupResolver(
    yup.object().shape({
        mfaCode: yup
            .string()
            .required(Message.GenericMsg)
            .min(6, 'Code should be 6 digit in length.')
            .max(6, 'Code should be 6 digit in length.'),
    })
);
export const ReportPanelMapValidation = yupResolver(
    yup.object().shape({
        defaultHtmlEnabler: yup.boolean(),
        colHtmlTypeName: yup.string().nullable().min(1).required(Message.GenericMsg),
        // fkImpactTypeId: yup
        //     .string()
        //     .nullable()
        //     .when('defaultHtmlEnabler', {
        //         is: true,
        //         then: yup.string().nullable().required(Message.GenericMsg),
        //     }),
    })
);

export const SystemFormValidation = yupResolver(
    yup.object().shape({
        id: yup.string(),
        name: yup.string().trim().required(Message.GenericMsg),
        domain: yup.string().trim().required(Message.GenericMsg).matches(urlValidator, Message.urlValidatorMessage),
        maxUsers: yup.string().max(7, 'Max user limit is 7 characters.').required(Message.GenericMsg),
        shareDomain: yup
            .string()
            .trim()
            .max(100)
            .required(Message.GenericMsg)
            .matches(urlValidator, Message.urlValidatorMessage),
        colFirstName: yup
            .string()
            .trim()
            .max(100)
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colLastName: yup
            .string()
            .trim()
            .max(100)
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        // templateStructure: yup.string().trim().required(Message.GenericMsg),
        colIsReportAccessible: yup.string().required(Message.GenericMsg),
        email: yup.string().matches(emailValidator, Message.emailValidatorMessage).required(Message.GenericMsg),
        colRecaptchaApiKey: yup.string().trim().required(Message.GenericMsg),
        colRecaptchaSiteKey: yup.string().trim().required(Message.GenericMsg),

        colIsReportAccessible: yup.boolean(),
        colMaxReports: yup
            .string()
            .nullable()
            .when('colIsReportAccessible', {
                is: true,
                then: yup.string().nullable().required(Message.GenericMsg),
            }),
        colIsSso: yup.boolean(),
        colIdpLoginUrn: yup
            .string()
            .nullable()
            .when('colIsSso', {
                is: true,
                then: yup.string().nullable().required(Message.GenericMsg),
            }),
        colIdpLogoutUrn: yup
            .string()
            .nullable()
            .when('colIsSso', {
                is: true,
                then: yup.string().nullable().required(Message.GenericMsg),
            }),
    })
);

export const PlatformAdminDetailsValidation = yupResolver(
    yup.object().shape({
        id: yup.string(),
        name: yup.string().trim().required(Message.GenericMsg),
        domain: yup.string().required(Message.GenericMsg).matches(urlValidator, Message.urlValidatorMessage),
        maxUsers: yup.string().max(30, 'Max user limit is 30 characters.').required(Message.GenericMsg),
        shareDomain: yup
            .string()
            .max(100)
            .required(Message.GenericMsg)
            .matches(urlValidator, Message.urlValidatorMessage),
        colRecaptchaApiKey: yup.string().trim().required(Message.GenericMsg),
        colRecaptchaSiteKey: yup.string().trim().required(Message.GenericMsg),
        colIsSso: yup.boolean(),
        colIdpLoginUrn: yup
            .string()
            .nullable()
            .when('colIsSso', {
                is: true,
                then: yup.string().nullable().required(Message.GenericMsg),
            }),
        colIdpLogoutUrn: yup
            .string()
            .nullable()
            .when('colIsSso', {
                is: true,
                then: yup.string().nullable().required(Message.GenericMsg),
            }),
    })
);

export const NewRoleNameValidation = yupResolver(
    yup.object().shape({
        name: yup.string().trim().required(Message.GenericMsg),
    })
);
export const NewRootNodeValidation = yupResolver(
    yup.object().shape({
        text: yup.string().trim().required(Message.GenericMsg),
    })
);

export const EmailTemplateValidation = yupResolver(
    yup.object().shape({
        colEmailTemplateName: yup
            .string()
            .trim()
            .required(Message.GenericMsg)
            .matches(nameValidator, Message.nameValidatorMessage),
        colEmailSubject: yup.string().trim().required(Message.GenericMsg),
        colEmailTemplate: yup.string().trim().required(Message.GenericMsg),
    })
);

export const resetModalFormValidation = yupResolver(
    yup.object().shape({
        currentPassword: yup.string().required(Message.GenericMsg),
        newPassword: yup
            .string()
            .required('Required.')
            .matches(/[A-Z]/, 'Please add one upper case.')
            .matches(/[a-z]/, 'Please add one lower case.')
            .matches(/[0-9]/, 'Please add one digit.')
            .min(8, 'Password should be 8-15 characters in length.')
            .max(15, 'Password should not be greater than 15 characters.')
            .matches(/^(?!.*(\w)\1{2,}).+$/, 'Contain no more than 2 consecutively repeating characters.')
            .matches(/[!@#$%^&+=]/, 'Please add a special character (!@#$%^&+=).'),
        confirmPassword: yup
            .string()
            .required(Message.GenericMsg)
            .oneOf([yup.ref('newPassword'), null], 'Confirm password must match with new password.'),
    })
);

export const UserValidation = yupResolver(
    yup.object().shape({
        colFirstName: yup
            .string()
            .trim()
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colMiddleName: yup
            .string()
            .trim()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colLastName: yup
            .string()
            .trim()
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        email: yup.string().trim().required(Message.GenericMsg).matches(emailValidator, Message.emailValidatorMessage),
    })
);

export const AddressDefaultValidation = yupResolver(
    yup.object().shape({
        colWebAddress: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colFacebookUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colContactNo: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(phoneValidator, Message.phoneValidatorMessage),
        colLinkedinUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colCity: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colStateProvince: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(15)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colZipPostalCode: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(20)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colCountry: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(45)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
    })
);

export const VimeoAccountValidation = yupResolver(
    yup.object().shape({
        vimeoToken: yup.string().trim().nullable().required(Message.GenericMsg),
        vimeoUploadUri: yup.string().trim().nullable().required(Message.GenericMsg),
    })
);

export const BroadcastValidation = yupResolver(
    yup.object().shape({
        name: yup.string().trim().required(Message.GenericMsg).matches(FREE_TEXT, Message.FREE_TEXT),
        htmlCode: yup.string().trim().required(Message.GenericMsg),
    })
);

export const BrandNameValidation = yupResolver(
    yup.object().shape({
        name: yup.string().trim().required(Message.GenericMsg),
    })
);

export const VerifyIdentityModalValidation = yupResolver(
    yup.object().shape({
        password: yup.string().required(Message.GenericMsg),
    })
);

export const AddNewBrandValidation = yupResolver(
    yup.object().shape({
        name: yup.string().trim().required(Message.GenericMsg),
    })
);

export const SiteEditElementValidation = yupResolver(
    yup.object().shape({
        colIndex: yup.string().trim().required(Message.GenericMsg),
        colContact: yup.string().trim().required(Message.GenericMsg),
        messageMaxCount: yup
            .string()
            .required(Message.GenericMsg)
            .matches(/^[0-9]*$/, 'Only numeric are allowed for this field.'),
        emailBugPath: yup.string().trim().required(Message.GenericMsg),
        bugTextDetails: yup.string().trim().required(Message.GenericMsg),
    })
);

export const ReportEditElementValidation = yupResolver(
    yup.object().shape({
        indexHtml: yup.string().required(Message.GenericMsg),
        contactHtml: yup.string().required(Message.GenericMsg),
        greetingMax: yup.string().required(Message.GenericMsg),
        emailPath: yup.string().required(Message.GenericMsg),
        emailDetails: yup.string().required(Message.GenericMsg),
    })
);

export const EditContentPanelValidation = yupResolver(
    yup.object().shape({
        name: yup.string().trim().required(Message.GenericMsg),
        imageSrc: yup.string().trim().required(Message.GenericMsg),
        htmlCode: yup.string().trim().required(Message.GenericMsg),
    })
);

export const SitePrintBridgeValidation = yupResolver(
    yup.object().shape({
        template: yup.string().trim().required(Message.GenericMsg),
        paletteId: yup.string().trim().required(Message.GenericMsg),
    })
);

export const ReportPrintBridgeValidation = yupResolver(
    yup.object().shape({
        paletteId: yup.string().trim().required(Message.GenericMsg),
        template: yup.string().trim().required(Message.GenericMsg),
    })
);
export const NewFundValidation = yupResolver(
    yup.object().shape({
        fkTblPaletteId: yup.string().trim().required(Message.GenericMsg),
        colFundTypeName: yup
            .string()
            .trim()
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colFundKey: yup
            .string()
            .trim()
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9_]*$/, 'Only alphanumeric are allowed for this field.'),
    })
);
export const NewImpactValidation = yupResolver(
    yup.object().shape({
        fkTblPaletteId: yup.string().trim().required(Message.GenericMsg),
        colImpactName: yup
            .string()
            .trim()
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactKey: yup
            .string()
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9_-]*$/, 'Only alphanumeric are allowed for this field.'),
    })
);
export const ReportPanelValidation = yupResolver(
    yup.object().shape({
        colHtmlTypeName: yup.string().trim().required(Message.GenericMsg),
        colHtml: yup.string().trim().required(Message.GenericMsg),
    })
);
export const userSettingFormValidation = yupResolver(
    yup.object().shape({
        firstName: yup.string().required(Message.GenericMsg),
        lastName: yup.string().required(Message.GenericMsg),
        email: yup.string().required(Message.GenericMsg),
    })
);
export const settingPageFormValidation = yupResolver(
    yup.object().shape({
        colFirstName: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .required(Message.GenericMsg)
            .max(50)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colLastName: yup
            .string()
            .required(Message.GenericMsg)
            .max(50)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colMiddleName: yup
            .string()
            .max(40)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        email: yup
            .string()
            .required('Please enter Username/Email.')
            .matches(emailValidator, 'Please enter a valid Username/Email.'),
        colWebAddress: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colFacebookUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colLinkedinUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colIsShowAdminDetailForAllSite: yup.string(),
        colEmailIdForAllSite: yup
            .string()
            .nullable()
            .when('colIsShowAdminDetailForAllSite', {
                is: 'true',
                then: yup
                    .string()
                    .nullable()
                    .required(Message.GenericMsg)
                    .matches(emailValidator, Message.emailValidatorMessage),
            }),
        colPhoneNoForAllSite: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(phoneValidator, Message.phoneValidatorMessage),
        colIsShowAdminDetailForAllReport: yup.string(),
        colEmailIdForAllReport: yup
            .string()
            .nullable()
            .when('colIsShowAdminDetailForAllReport', {
                is: 'true',
                then: yup
                    .string()
                    .nullable()
                    .required(Message.GenericMsg)
                    .matches(emailValidator, Message.emailValidatorMessage),
            }),
        colPhoneNoForAllReport: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(phoneValidator, Message.phoneValidatorMessage),
        colContactNo: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(phoneValidator, Message.phoneValidatorMessage),
        colAddress1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colCity: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colStateProvince: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(15)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colZipPostalCode: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(20)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colCountry: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(45)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
    })
);
export const UserSettingResetPasswordValidation = yupResolver(
    yup.object().shape({
        currentPassword: yup.string().required(Message.GenericMsg),
        newPassword: yup.string().required(Message.GenericMsg),
        confirmPassword: yup.string().required(Message.GenericMsg),
    })
);
export const CreateNewUserFormValidation = yupResolver(
    yup.object().shape({
        colFirstName: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .required(Message.GenericMsg)
            .max(50)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colLastName: yup
            .string()
            .required(Message.GenericMsg)
            .max(50)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colMiddleName: yup
            .string()
            .max(40)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRemoteLoginId: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable(),
        email: yup
            .string()
            .required('Please enter Username/Email.')
            .matches(emailValidator, 'Please enter a valid Username/Email.'),
    })
);
export const analyticsValidation = yupResolver(
    yup.object().shape({
        colThirdPartyTrackingCodeEnabled: yup.boolean(),
        colThirdPartyTrackingCode: yup.string().when('colThirdPartyTrackingCodeEnabled', {
            is: true,
            then: yup.string().required(Message.GenericMsg),
        }),
    })
);
export const GroupNameValidation = yupResolver(
    yup.object().shape({
        name: yup.string().required(Message.GenericMsg).matches(GROUP_NAME, Message.GROUP_NAME),
    })
);
export const folderNameValidation = yupResolver(
    yup.object().shape({
        name: yup
            .string()
            .required(Message.GenericMsg)
            .max(30)
            .matches(
                /^[a-zA-Z0-9\s,&;():_'-]+$/,
                'Incompatible special characters; ' + boldString(": - ' , ( ) & _ ;") + ' permitted.'
            ),
    })
);
export const folderRenameValidation = yupResolver(
    yup.object().shape({
        renameFolder: yup
            .string()
            .required(Message.GenericMsg)
            .max(30)
            .matches(
                /^[a-zA-Z0-9\s,&;():_'-]+$/,
                'Incompatible special characters; ' + boldString(": - ' , ( ) & _ ;") + ' permitted.'
            ),
    })
);
export const editRecordAndSettingsFormValidation = yupResolver(
    yup.object().shape({
        colRecordLastName: yup
            .string()
            .nullable()
            .max(100)
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordSpouseOrPartnerReferenceNo: yup
            .string()
            .nullable()
            .max(30)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            ),
        colRecordReferenceNo2: yup
            .string()
            .nullable()
            .max(30)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            ),
        colRecordReferenceNo3: yup
            .string()
            .nullable()
            .max(30)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            ),
        colRecordReferenceNo4: yup
            .string()
            .nullable()
            .max(30)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            ),
        colRecordReferenceNo: yup
            .string()
            .nullable()
            .max(30)
            .required(Message.GenericMsg)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            ),
        colRecordUrlExtension: yup
            .string()
            .nullable()
            .max(250)
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9]+$/, Message.spaceAndSpecialCharacterValidatiorMessage),
        colPUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .max(250)
            .matches(urlValidator, Message.urlValidatorMessage),
        colPrimaryEmailAddress: yup.string().nullable().matches(emailValidator, Message.emailValidatorMessage),
        colRecordPrimaryEmailAddress: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(emailValidator, Message.emailValidatorMessage),
        colSecondaryEmailAddress: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(emailValidator, Message.emailValidatorMessage),
        colRecordFacebook: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colRecordLinkedin: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colRecordInstagram: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colRecordSnapchat: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colRecordGreetingName: yup
            .string()
            .max(80)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colAlternateGreetingName: yup
            .string()
            .max(80)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordFirstName: yup
            .string()
            .max(100)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordMiddleName: yup
            .string()
            .max(40)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordPrefix: yup
            .string()
            .max(30)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordSuffix: yup
            .string()
            .max(30)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordTitle: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordCampus: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colRecordSchoolCollege: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(120)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colRecordDepartment: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colRecordMajor: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colRecordClassYear: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(CLASS_YEAR, Message.CLASS_YEAR),
        colRecordAddressName: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colRecordAddress1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colRecordAddress2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colRecordAddress3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colRecordAddress4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colRecordCity: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordStateProvince: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(15)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordZipPostalCode: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(20)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordCountry: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(45)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordManagementFirstName: yup
            .string()
            .max(100)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordManagementMiddleName: yup
            .string()
            .max(40)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordManagementLastName: yup
            .string()
            .nullable()
            .max(100)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordManagementEmail: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(emailValidator, Message.emailValidatorMessage),
        colRecordManagementPhoneNumber: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(phoneValidator, Message.phoneValidatorMessage),
        // colTags: yup
        //     .string()
        //     .transform((v, o) => (o === '' ? null : v))
        //     .max(45)
        //     .nullable()
        //     .matches(
        //         /^[a-zA-Z0-9\s,.:_'"-]+$/,
        //         'Incompatible special characters; ' + boldString("_ : - ' , .") + ' permitted.'
        //     ),
    })
);
export const ResetPasswordAuthenticationValidation = yupResolver(
    yup.object().shape({
        accountPassword: yup.string().required(),
    })
);
export const ResetPasscodeValidation = yupResolver(
    yup.object().shape({
        currentPasscode: yup.string().required(Message.GenericMsg),
        newPasscode: yup
            .string()
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9_]*$/, 'Passcode must be alphanumeric.')
            .min(5, 'Passcode must be 5 characters.')
            .max(5, 'Passcode must be 5 characters.'),
    })
);
export const RecordSettingResetPasscodeValidation = yupResolver(
    yup.object().shape({
        accountPassword: yup
            .string()
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9_]*$/, 'Passcode must be alphanumeric.')
            .min(5, 'Passcode must be 5-digit alpha-numeric.')
            .max(5, 'Passcode must be 5-digit alpha-numeric.'),
    })
);

export const PortfolioResetPasscodeValidation = yupResolver(
    yup.object().shape({
        //currentPasscode: yup.string().required(Message.GenericMsg),
        newPasscode: yup
            .string()
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9_]*$/, 'Passcode must be alphanumeric.')
            .min(5, 'Passcode must be 5 characters.')
            .max(5, 'Passcode must be 5 characters.'),
        confirmPasscode: yup
            .string()
            .required(Message.GenericMsg)
            .min(5, 'Passcode must be 5 characters.')
            .max(5, 'Passcode must be 5 characters.')
            .oneOf([yup.ref('newPasscode'), null], 'Confirm passcode must match with new passcode.'),
    })
);
export const ThumbnailModalValidation = yupResolver(
    yup.object().shape({
        useVideoThumbnail: yup.string(),
        file: yup.string(),
        useCurrentFrameThumbnail: yup.string(),
        trimStart: yup.string(),
        trimEnd: yup.string(),
        clickForSound: yup.string(),
        showTextOnHover: yup.string(),
        thumbnailAltText: yup.string(),
        includeTextOverlay: yup.string(),
        textOverlay: yup.string(),
        // .required(Message.GenericMsg)
    })
);
export const NewRecordValidation = yupResolver(
    yup.object().shape({
        colRecordReferenceNo: yup
            .string()
            .required(Message.GenericMsg)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            ),
        colRecordOwnerEmailId: yup.string().required(Message.GenericMsg),
        colRecordUrlExtension: yup
            .string()
            .max(250)
            .required(Message.GenericMsg)
            .matches(/^[a-zA-Z0-9]+$/, Message.spaceAndSpecialCharacterValidatiorMessage),
        colRecordPasscode: yup
            .string()
            .min(5, 'Passcode must 5 digits.')
            .max(5, 'Passcode must 5 digits.')
            .required(Message.GenericMsg),
        colRecordLastName: yup
            .string()
            .max(100)
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colRecordFirstName: yup
            .string()
            .max(100)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
    })
);
export const EditUserProfileInfoFormValidation = yupResolver(
    yup.object().shape({
        classYear: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(CLASS_YEAR, Message.CLASS_YEAR),
        pOther: yup
            .string()
            .max(30)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        sOther: yup
            .string()
            .max(30)
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        bioNickName: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        offNum: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(phoneValidator, Message.phoneValidatorMessage),
        mobNum: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(phoneValidator, Message.phoneValidatorMessage),
        faxNum: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(phoneValidator, Message.phoneValidatorMessage),
        webUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        facUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        linkUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colInstagramUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colSnapchatUrl: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(250)
            .nullable()
            .matches(urlValidator, Message.urlValidatorMessage),
        colCampus: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colSchoolCollege: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(120)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colDepartment: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS),
        colAddressName: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colAddress4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(ADDRESS, Message.ADDRESS),
        colCity: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colStateProvince: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(15)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colZipPostalCode: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(20)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colCountry: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(45)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
    })
);
export const ApplyAdminBrand = yupResolver(
    yup.object().shape({
        brandId: yup.string().required(Message.GenericMsg),
    })
);

export const fundDetailsValidation = yupResolver(
    yup.object().shape({
        colFundName: yup
            .string()
            .nullable()
            .required(Message.GenericMsg)
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(120),
        colFundReferenceNo: yup
            .string()
            .nullable()
            .required(Message.GenericMsg)
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            )
            .max(30),
        colFundCampus: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS)
            .max(40),
        colFundDepartment: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS)
            .max(40),
        colFundSchoolCollege: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(ALPHANUMERIC_WITH_SPECIAL_CHARACTERS, Message.ALPHANUMERIC_WITH_SPECIAL_CHARACTERS)
            .max(120),
        colFundImpactArea: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(50),
    })
);
export const fundPerformanceValidation = yupResolver(
    yup.object().shape({
        colFpFeesFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpMatchFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpEndingValueFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpTotalGifts: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpProjectedDistributionFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpGiftsFy2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpGiftsFy3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpGiftsFy4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpBeginningValueFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpGainsLossesFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpDistributionsFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colCurrentUseGiftsFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpOtherTransfersFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpOtherTransfersFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpOtherTransfersFy2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpOtherTransfersFy3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpOtherTransfersFy4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFy0PercentageGrowth: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpGiftsFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpBeginningValueFy2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpBeginningValueFy3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpBeginningValueFy4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpPayoutReturnedFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpPayoutReturnedFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpPayoutReturnedFy2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpPayoutReturnedFy3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpPayoutReturnedFy4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpBeginningValueFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpGiftsFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpEndingValuePriorYearFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpEndingValuePriorYearFy2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpEndingValuePriorYearFy3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpEndingValuePriorYearFy4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFy1PercentageGrowth: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFy2PercentageGrowth: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFy3PercentageGrowth: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFy4PercentageGrowth: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpDistributionsFy1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpDistributionsFy2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpDistributionsFy3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpDistributionsFy4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFpMatchNameFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(MATCH_NAME, Message.MATCH_NAME)
            .max(40),
    })
);
export const fundDonorsValidation = yupResolver(
    yup.object().shape({
        colFdAmount: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFdTotalAmountGivenToFund: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
        colFdMatchFy0: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(numberWithHyphenAndDotValidator, Message.numberWithHyphenAndDotValidatorMessage)
            .max(15),
    })
);
export const createContentPanel = yupResolver(
    yup.object().shape({
        newCpName: yup
            .string()
            .nullable()
            .required(Message.GenericMsg)
            .matches(
                /^[A-Za-z0-9¡-ÿœŸƒ\s~!@#$%&*()_+|:?,.\/;'‘\[\]\\=-]*$/,
                'Incompatible special characters; ' +
                    boldString("! @ # $ % & * ( ) _ + - = ~  | [ ] ; ' : , .") +
                    ' permitted.'
            ),
    })
);
export const renameContentPanel = yupResolver(
    yup.object().shape({
        renameContentPanel: yup
            .string()
            .nullable()
            .max(30)
            .required(Message.GenericMsg)
            .matches(
                /^[A-Za-z0-9¡-ÿœŸƒ\s~!@#$%&*()_+|:?,.\/;'‘\[\]\\=-]*$/,
                'Incompatible special characters; ' +
                    boldString("! @ # $ % & * ( ) _ + - = ~  | [ ] ; ' : , .") +
                    ' permitted.'
            ),
    })
);
export const renameAsset = yupResolver(
    yup.object().shape({
        renameContentPanel: yup
            .string()
            .nullable()
            .max(30)
            .required(Message.GenericMsg)
            .matches(
                /^[A-Za-z0-9¡-ÿœŸƒ\s~!@#$%&*()_+|:?,.\/;'‘\[\]\\=-]*$/,
                'Incompatible special characters; ' +
                    boldString("! @ # $ % & * ( ) _ + - = ~  | [ ] ; ' : , .") +
                    ' permitted.'
            ),
    })
);

export const embedLinkValidation = yupResolver(
    yup.object().shape({
        embedUrl: yup
            .string()
            .nullable()
            .max(500)
            .required(Message.GenericMsg)
            .matches(urlValidator, 'Please enter a valid embed url.'),
    })
);

export const editImpactDetailValidation = yupResolver(
    yup.object().shape({
        colImpactReferenceNo: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(
                spaceAndSpecialCharacterValidatior,
                'Incompatible special characters; ' + boldString('- _') + ' permitted.'
            )
            .max(30),
        colImpactPrefix: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(30),
        colImpactFirstName: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(50),
        colImpactMiddleName: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(40),
        colImpactLastName: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(50),
        colImpactSuffix: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(50),
        colImpactTitleProgramName: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(120),
        colImpactYear: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(IMPACT_YEAR, Message.IMPACT_YEAR)
            .max(20),
        colImpactClassYear: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(CLASS_YEAR, Message.CLASS_YEAR)
            .max(40),
        colImpactCampus: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(40),
        colImpactDepartment: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(75),
        colActivities: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(100),
        colLanguages: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(100),
        colInterests: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS)
            .max(100),
        colImpactEmailAddress: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(emailValidator, Message.emailValidatorMessage),
        colImpactFacebookUrl: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(urlValidator, Message.urlValidatorMessage),
        colImpactLinkedinUrl: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(urlValidator, Message.urlValidatorMessage),
        colImpactInstagram: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(urlValidator, Message.urlValidatorMessage),
        colImpactSnapchatUrl: yup
            .string()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .matches(urlValidator, Message.urlValidatorMessage),
        colImpactAddressName: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactAddress1: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactAddress2: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactAddress3: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactAddress4: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(80)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactCity: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(40)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactState: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(15)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactCountry: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(45)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
        colImpactZipPostalCode: yup
            .string()
            .transform((v, o) => (o === '' ? null : v))
            .max(20)
            .nullable()
            .matches(NAME_AND_IMPACT_FIELDS, Message.NAME_AND_IMPACT_FIELDS),
    })
);
