// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { mediaThumbnailGet, mediaThumbnail, mediaReset } from '@ovrture/react-redux';
import CustomTimePicker from '../../../helpers/timePicker/CustomTimePicker';

const ThumbnailModal = (props) => {
    const [thumbnailType, setthumbnailType] = useState('Image');
    const [fileName, setfileName] = useState('');
    const [chooseFile, setChooseFile] = useState('');
    const [clickForSound, setclickForSound] = useState(false);
    const { modalDispatch } = useModalContext();
    const mediaId = new URL(props?.data?.mediaElement?.src).pathname?.split('/')[3];
    const dispatch = useDispatch();
    let { fetchedData, saved } = useSelector((state) => ({
        fetchedData: state?.mediaReducer?.thumbnailGet?.data,
        saved: state?.mediaReducer?.thumbnail,
    }));
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        dispatch(mediaThumbnailGet({ mediaId: mediaId }));
        setValue('thumbnailAltText', 'a video thumbnail');
        setValue('useVideoThumbnail', 'false');
    }, []);

    useEffect(() => {
        if (fetchedData) {
            if (fetchedData?.useVideoThumbnail) {
                setValue('useVideoThumbnail', 'true');
                setthumbnailType('Video');
                setValue('trimStart', fetchedData?.trimStart && convertSecondsToTime(fetchedData?.trimStart));
                setValue('trimEnd', fetchedData?.trimEnd && convertSecondsToTime(fetchedData?.trimEnd));
                setValue('clickForSound', fetchedData?.clickForSound);
                if (fetchedData?.clickForSound) {
                    setclickForSound(true);
                } else {
                    setclickForSound(false);
                }
                setValue('showTextOnHover', fetchedData?.showTextOnHover);
                setValue('currentFrameTime', '0');
            } else {
                setValue('useVideoThumbnail', 'false');
                setthumbnailType('Image');
                setValue('useCurrentFrameThumbnail', fetchedData?.useCurrentFrameThumbnail);
                setValue('currentFrameTime', fetchedData?.currentFrameTime || '0');
            }
            setValue('thumbnailAltText', fetchedData?.thumbnailAltText);
            setValue('includeTextOverlay', fetchedData?.includeTextOverlay);
            setValue('textOverlay', fetchedData?.textOverlay);
            dispatch(mediaReset());
        }
    }, [fetchedData]);

    useEffect(() => {
        if (saved) {
            props.data.mediaElement.src = saved?.thumbnailUrl || props?.data?.mediaElement?.src;
            // props?.data?.mediaElement?.setAttribute('alt', getValues('thumbnailAltText'));
            reset();
            dispatch(mediaReset());
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
                payload: { noActive: true },
            });
        }
    }, [saved]);

    const convertTimeToSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString?.split(':').map(Number);
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        return totalSeconds;
    };

    const convertSecondsToTime = (timeString) => {
        let hours = Math.floor(timeString / 3600);
        let minutes = Math.floor((timeString % 3600) / 60);
        let seconds = Math.floor(timeString % 60);
        if (hours?.toString()?.length === 1) {
            hours = `0${hours}`;
        }
        if (minutes?.toString()?.length === 1) {
            minutes = `0${minutes}`;
        }
        if (seconds?.toString()?.length === 1) {
            seconds = `0${seconds}`;
        }
        return `${hours}:${minutes}:${seconds}`;
    };

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('mediaId', mediaId);
        if (data?.useVideoThumbnail === 'true') {
            data?.trimStart && formData.append('trimStart', convertTimeToSeconds(data?.trimStart));
            data?.trimEnd &&
                formData.append(
                    'trimEnd',
                    convertTimeToSeconds(data?.trimEnd === '00:00:00' ? '00:05:00' : data?.trimEnd)
                );
            data?.clickForSound && formData.append('clickForSound', data?.clickForSound);
            data?.showTextOnHover && formData.append('showTextOnHover', data?.showTextOnHover);
            formData.append('useDifferentVideoForThumbnail', false);
            formData.append('videoThumbnailMediaId', '');
            formData.append('useCurrentFrameThumbnail', false);
        } else {
            if (chooseFile) {
                chooseFile && formData.append('file', chooseFile);
                formData.append('useCurrentFrameThumbnail', false);
                formData.append('currentFrameTime', '0');
            } else {
                data?.useCurrentFrameThumbnail
                    ? formData.append('useCurrentFrameThumbnail', data?.useCurrentFrameThumbnail)
                    : formData.append('useCurrentFrameThumbnail', false);
                data?.currentFrameTime
                    ? formData.append('currentFrameTime', data?.currentFrameTime)
                    : formData.append('currentFrameTime', '0');
            }
        }
        data?.useVideoThumbnail && formData.append('useVideoThumbnail', data?.useVideoThumbnail);
        data?.thumbnailAltText && formData.append('thumbnailAltText', data?.thumbnailAltText);
        data?.includeTextOverlay && formData.append('includeTextOverlay', data?.includeTextOverlay);
        data?.textOverlay && formData.append('textOverlay', data?.textOverlay);
        dispatch(
            mediaThumbnail({
                data: formData,
            })
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="wistia-thumbnail">
            <Row>
                <b className="mb-1 d-block">Type</b>
                <FormInput
                    type="radio"
                    id="useVideoThumbnail"
                    className="ms-3"
                    label="Image"
                    errors={errors}
                    register={register}
                    defaultValue="false"
                    defaultChecked
                    onChange={() => setthumbnailType('Image')}
                />
                <FormInput
                    type="radio"
                    id="useVideoThumbnail"
                    className="ms-3 mt-1"
                    label="Video Loop"
                    errors={errors}
                    register={register}
                    defaultValue="true"
                    onChange={() => setthumbnailType('Video')}
                />
            </Row>
            <div className="configure">
                <b className="mb-1 d-block mt-3">Configure</b>
                {thumbnailType === 'Image' ? (
                    <>
                        <Row>
                            <Col className="ms-2">
                                <p
                                    className="upload-image"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementsByName('file')[0].click();
                                    }}>
                                    {fileName ? `(${fileName})` : 'Upload a new image'}
                                </p>
                                <FormInput
                                    type="file"
                                    id="file"
                                    errors={errors}
                                    accept=".png, .jpg, .jpeg, .gif, .PNG, .JPG, .JPEG, .GIF"
                                    register={register}
                                    onChange={(e) => {
                                        if (e?.target?.files[0]?.name) {
                                            setChooseFile(e.target.files[0]);
                                            setfileName(e?.target?.files[0]?.name);
                                        } else {
                                            setChooseFile('');
                                            setfileName('');
                                        }
                                    }}
                                />
                                <p className="mb-1 mt-1">or</p>
                                <FormInput
                                    type="checkbox"
                                    id="useCurrentFrameThumbnail"
                                    label="Use the current frame"
                                    register={register}
                                    className="mb-2"
                                />
                                <Col md={6} className="currentTimeLabel">
                                    Current frame time (in seconds)
                                </Col>
                                <Col md={6} className="currentTime">
                                    <FormInput
                                        type="number"
                                        id="currentFrameTime"
                                        label="Current frame time"
                                        floatinglabel="false"
                                        errors={errors}
                                        register={register}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Col>
                            <Col md={4} className="startTimeLabel">
                                Start time
                            </Col>
                            <Col md={8} className="startTime">
                                <CustomTimePicker
                                    id="trimStart"
                                    getFormattedTime={(time) => {
                                        setValue('trimStart', time);
                                    }}
                                    register={register('trimStart')}
                                    getValues={getValues('trimStart')}
                                    defaultValues={'00:00:00'}
                                />
                            </Col>
                            <Col md={4} className="endTimeLabel">
                                End time
                            </Col>
                            <Col md={8} className="endTime mb-1">
                                <CustomTimePicker
                                    id="trimEnd"
                                    getFormattedTime={(time) => {
                                        setValue('trimEnd', time);
                                    }}
                                    register={register('trimEnd')}
                                    getValues={getValues('trimEnd')}
                                    defaultValues={'00:05:00'}
                                />
                            </Col>

                            <FormInput
                                type="checkbox"
                                id="clickForSound"
                                label="Click for sound"
                                register={register}
                                className="mb-1 mt-2 d-none"
                                onChange={(e) => {
                                    setclickForSound(e.target.checked);
                                    // setValue('showTextOnHover', e?.target?.checked);
                                    if (!e?.target?.checked) {
                                        setValue('showTextOnHover', false);
                                    }
                                }}
                            />
                            <FormInput
                                type="checkbox"
                                id="showTextOnHover"
                                label="Show text on hover"
                                register={register}
                                className={clickForSound ? 'mb-3 d-none' : 'mb-3 disabled d-none'}
                            />
                        </Col>
                    </Row>
                )}
            </div>
            <Row className="mt-2 d-none">
                <Col>
                    <b className="mb-1 d-block">Accessibility</b>
                    <FormInput
                        type="text"
                        id="thumbnailAltText"
                        label="Alt text"
                        maxLength="100"
                        errors={errors}
                        register={register}
                    />
                </Col>
            </Row>
            <Row className="d-none">
                <Col className="textOverlay">
                    <b className="mb-1 d-block">Text Overlay</b>
                    <FormInput
                        type="checkbox"
                        id="includeTextOverlay"
                        label="Include text overlay"
                        register={register}
                        className="mb-2"
                    />
                    <FormInput
                        type="text"
                        id="textOverlay"
                        label="Text"
                        maxLength="100"
                        errors={errors}
                        register={register}
                        className="mb-0"
                    />
                    <p className="optinal-text">* Optional</p>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-2">
                <ButtonElement name="Save" variant="outline-primary" type="submit" />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        })
                    }
                />
            </div>
        </form>
    );
};

export default ThumbnailModal;
