export const learnMoreDisplayShouldFlex = ['48', '34'];
export const learnMoreDisplayShouldTable = ['81', '58', '74', '61', '69', '86', '64', '65', '46', '60', '37', '87'];
export const addClassToCarausalSectionChecker = ['19'];
export const changeClassForCurrentFund = ['31'];
export const checkOrgIdForLoginLayout = [
    50, 57, 56, 59, 62, 26, 61, 64, 66, 16, 70, 72, 68, 17, 67, 69, 75, 77, 74, 58, 46, 76, 63, 18, 28, 80, 81, 82, 83,
    84, 48,
];
export const checkOrgIdForInternalUseText = [
    80, 81, 83, 82, 57, 59, 62, 26, 61, 64, 66, 16, 70, 72, 68, 17, 67, 69, 75, 77, 74, 58, 46, 76, 63, 18, 28, 84, 48,
];
export const checkOrgIdForPngLogo = [1, 7, 8, 16, 19, 25, 27, 29, 31, 33, 34, 35, 36, 37, 38, 41, 50, 54, 55, 57, 85];
export const assetDisabledForFundDetails = [69];
export const checkRoleIdForFilter = [0, 1, 2, 5, 6, 7, 9, 10];
export const checkOrgForImpactImageForCropper = [52, 53, 78, 79];
export const checkForLockIconPositionOnLeft = [87];
export const menuCheckerForOnClickNotHavingLi = [87, 88, 89, 90, 92, 94];
export const notRequiredAmountConversion = [89];
export const letterPanelFeature = ['89', '74', '88', '90', '72', '92', '93', '1'];
export const learnMoreDisplayFeature = [1, 71, 85, 94, 98, 95];
export const broadcastReadMoreTextAndIconHandler = [1, 27, 33, 38];
export const containerFluidFixesChecker = [
    47, 34, 40, 37, 38, 42, 51, 50, 39, 55, 63, 44, 36, 41, 35, 60, 46, 77, 16, 41, 45, 58, 25, 48, 31, 8, 26,
];
export const customPanelYearReplacerForReport = [
    68, 45, 84, 80, 63, 42, 70, 58, 59, 65, 87, 88, 89, 90, 91, 94, 71, 96, 98, 95,
];
export const customPanelYearReplacerForSite = [84, 80, 63, 42, 70, 87, 88, 89, 90, 91, 94, 58, 71, 96, 98, 95];
export const showCarouselIndicatorNumber = [1, 94];
export const numberFormatStartWithZero = [1];
export const reactAppS3URLVersion1 = [1, 71, 96, 98, 95];
export const checkScrollRoutes = [
    'users-create',
    'systemsettings-btd',
    'reportdata-edit-performance',
    'cloud-details',
    'content-broadcast',
    'systemsettings-analytics',
    'systemsettings-brands',
    'systemsettings-records',
    'systemsettings-reports',
    'systemsettings',
    'systemsettings-sites',
    'systemsettings-surveys',
    'systemsettings-groups',
];
