import React, { forwardRef } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import ButtonElement from '../../button/ButtonElement';

export const FormInput = forwardRef(
    ({ label, ariaLabel, type, register, errors, customError, className, children, id, as, ...props }, ref) => {
        // Function for mainting the type number and max length with pattern
        const numberOnly = (event) => {
            var element = event.currentTarget;
            if (element?.attributes?.negative?.value === 'false') {
                element.value = element?.value?.replace(/[^0-9.]/gi, '');
            } else if (element?.attributes?.negative?.value === 'true') {
                element.value = element?.value?.replace(/[^0-9-.]/gi, '');
            } else if (element?.attributes?.allowedKey?.value === 'commaAndSpace') {
                element.value = element?.value?.replace(/[^0-9, ]/gi, '');
            } else element.value = element?.value?.replace(/[^0-9]/gi, '');
        };
        return (
            <>
                {(type === 'text' ||
                    type === 'password' ||
                    type === 'textarea' ||
                    type === 'email' ||
                    type === 'time') &&
                    (props?.floatinglabel !== 'false' ? (
                        <FloatingLabel
                            controlId={id}
                            label={label}
                            className={(className ? className : 'mb-2') + (errors?.[id] ? ' is-invalid' : '')}>
                            <Form.Control
                                type={type && type}
                                as={as && as}
                                ref={ref && ref}
                                placeholder={label}
                                aria-label={ariaLabel ? ariaLabel : label}
                                className={errors?.[id] || customError ? 'is-invalid' : ''}
                                {...(register && register(id))}
                                {...props}
                            />
                            {props?.feedback !== 'hide' ? (
                                <>
                                    {' '}
                                    {customError ? (
                                        <Form.Control.Feedback type="invalid">
                                            {customError && parse(customError)}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.[id]?.message && parse(errors?.[id]?.message)}
                                        </Form.Control.Feedback>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </FloatingLabel>
                    ) : (
                        <>
                            <Form.Control
                                type={type && type}
                                as={as && as}
                                ref={ref && ref}
                                placeholder={label}
                                className={
                                    (className ? className : '') + (errors?.[id] || customError ? ' is-invalid' : '')
                                }
                                {...(register && register(id))}
                                {...props}
                            />
                            {props?.feedback !== 'hide' ? (
                                <>
                                    {' '}
                                    {customError ? (
                                        <Form.Control.Feedback type="invalid">
                                            {customError && parse(customError)}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.[id]?.message && parse(errors?.[id]?.message)}
                                        </Form.Control.Feedback>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    ))}
                {type === 'number' &&
                    (props?.floatinglabel !== 'false' ? (
                        <FloatingLabel
                            controlId={id}
                            label={label}
                            className={(className ? className : 'mb-2') + (errors?.[id] ? ' is-invalid' : '')}>
                            <Form.Control
                                type="text"
                                as={as && as}
                                ref={ref && ref}
                                placeholder={label}
                                className={errors?.[id] || customError ? 'is-invalid' : ''}
                                {...(register && register(id))}
                                {...props}
                                onInput={numberOnly}
                            />
                            {props?.feedback !== 'hide' ? (
                                <>
                                    {' '}
                                    {customError ? (
                                        <Form.Control.Feedback type="invalid">
                                            {customError && parse(customError)}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.[id]?.message && parse(errors?.[id]?.message)}
                                        </Form.Control.Feedback>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </FloatingLabel>
                    ) : (
                        <>
                            <Form.Control
                                type="text"
                                as={as && as}
                                ref={ref && ref}
                                placeholder={label}
                                className={errors?.[id] || customError ? 'is-invalid' : ''}
                                {...(register && register(id))}
                                {...props}
                                onInput={numberOnly}
                            />
                            {props?.feedback !== 'hide' ? (
                                <>
                                    {customError ? (
                                        <Form.Control.Feedback type="invalid">
                                            {customError && parse(customError)}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.[id]?.message && parse(errors?.[id]?.message)}
                                        </Form.Control.Feedback>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    ))}
                {type === 'file' && (
                    <>
                        <Form.Control
                            type={type && type}
                            as={as && as}
                            ref={ref && ref}
                            placeholder={label}
                            className={errors?.[id] && 'is-invalid'}
                            {...(register && register(id))}
                            {...props}
                        />
                        {errors?.[id]?.message && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.[id]?.message && parse(errors?.[id]?.message)}
                            </Form.Control.Feedback>
                        )}
                    </>
                )}
                {type === 'switch' && (
                    <Form.Check
                        type={type}
                        label={label}
                        aria-label={label}
                        id={id}
                        className={(errors?.[id] && 'is-invalid', `${className ? className : 'col-md-4 mb-2'}`)}
                        {...(register && register(id))}
                        {...props}
                    />
                )}
                {type === 'radio' && (
                    <Form.Check
                        type={type}
                        label={label}
                        aria-label={label}
                        //id={id}
                        name={id}
                        className={(errors?.[id] && 'is-invalid', `${className ? className : 'col-md-4 mb-2'}`)}
                        {...(register && register(id))}
                        {...props}
                    />
                )}
                {type === 'radioWithOptions' && (
                    <Form.Check
                        type={'radio'}
                        // label={label}
                        label={
                            <>
                                <i className={props?.iconName}></i> {label}
                                <span className="d-inline-block">
                                    <ButtonElement
                                        className="btn p-0 custom-tooltip-align"
                                        icon={<i className="dripicons-information"></i>}
                                        tooltipAlign="right"
                                        tooltip={props?.descriptorText}
                                    />
                                </span>
                            </>
                        }
                        aria-label={label}
                        //id={id}
                        name={id}
                        className={(errors?.[id] && 'is-invalid', `${className ? className : 'col-md-4 mb-2'}`)}
                        {...(register && register(id))}
                        {...props}
                    />
                )}
                {type === 'select' && (
                    <div className={className ? className : 'mb-3'}>
                        {props?.floatinglabel !== 'false' ? (
                            <FloatingLabel
                                controlId={props?.floatingSelectName ? props?.floatingSelectName : 'floatingSelect'}
                                label={label ? label : 'Select'}
                                className={errors?.[id] && 'is-invalid'}>
                                <Form.Select
                                    id={props?.floatingSelectName ? props?.floatingSelectName : 'floatingSelect'}
                                    type={type}
                                    {...(register && register(id))}
                                    aria-label={ariaLabel ? ariaLabel : label}
                                    {...props}
                                    className={errors?.[id] && 'is-invalid'}>
                                    {children}
                                </Form.Select>
                            </FloatingLabel>
                        ) : (
                            <Form.Select
                                type={type}
                                {...(register && register(id))}
                                aria-label={label}
                                {...props}
                                className={errors?.[id] && 'is-invalid'}>
                                {children}
                            </Form.Select>
                        )}
                    </div>
                )}
                {type === 'checkbox' && (
                    <div className={className ? className : 'mb-3'}>
                        <Form.Check
                            {...(register && register(id))}
                            type={type}
                            id={id}
                            label={label}
                            aria-label={ariaLabel ? ariaLabel : label}
                            {...props}>
                            {children}
                        </Form.Check>
                    </div>
                )}
            </>
        );
    }
);
